
import {SETTINGS_TYPES} from "@/constant";
const SET_IS_SHOW_GENDER_COLLEAGE = 'SET_IS_SHOW_GENDER_COLLEAGE';
const SET_ITEM_SETTINGS = 'SET_ITEM_SETTINGS';
const SET_STATUS_SETTINGS = 'SET_STATUS_SETTINGS';

let storeData = localStorage.getItem(SETTINGS_TYPES.GENERAL);
const initialState1 = storeData ? JSON.parse(storeData) : {};

const initialState2 = {
    //Colleague
    isShowGenderColleague: localStorage.getItem('isShowGenderColleague')? localStorage.getItem('isShowGenderColleague') : true,
    isShowDateOfBirthColleague: localStorage.getItem('isShowDateOfBirthColleague')? localStorage.getItem('isShowDateOfBirthColleague') : true,
    isShowCityColleague: localStorage.getItem('isShowCityColleague')? localStorage.getItem('isShowCityColleague') : true,
    isShowRegistrationAddressColleague: localStorage.getItem('isShowRegistrationAddressColleague')? localStorage.getItem('isShowRegistrationAddressColleague') : true,
    isShowEducationColleague: localStorage.getItem('isShowEducationColleague')? localStorage.getItem('isShowEducationColleague') : true,
    isShowExperienceColleague: localStorage.getItem('isShowExperienceColleague')? localStorage.getItem('isShowExperienceColleague') : true,
    isShowLinkToVKSColleague: localStorage.getItem('isShowLinkToVKSColleague')? localStorage.getItem('isShowLinkToVKSColleague') : true,
    isShowLinkToSocialMediaNetColleague: localStorage.getItem('isShowLinkToSocialMediaNetColleague')? localStorage.getItem('isShowLinkToSocialMediaNetColleague') : true,
    isShowAboutMeColleague: localStorage.getItem('isShowAboutMeColleague')? localStorage.getItem('isShowAboutMeColleague') : true,
    isShowAgreementsColleague: localStorage.getItem('isShowAgreementsColleague')? localStorage.getItem('isShowAgreementsColleague') : true,
    isShowWorkSheduleColleague: localStorage.getItem('isShowWorkSheduleColleague')? localStorage.getItem('isShowWorkSheduleColleague') : true,
    //Child
    isShowPhoneChild: localStorage.getItem('isShowPhoneChild')? localStorage.getItem('isShowPhoneChild') : true,
    isShowEmailChild: localStorage.getItem('isShowEmailChild')? localStorage.getItem('isShowEmailChild') : true,
    isShowGenderChild: localStorage.getItem('isShowGenderChild')? localStorage.getItem('isShowGenderChild') : true,
    isShowDateOfBirthChild: localStorage.getItem('isShowDateOfBirthChild')? localStorage.getItem('isShowDateOfBirthChild') : true,
    isShowClassChild: localStorage.getItem('isShowClassChild')? localStorage.getItem('isShowClassChild') : true,
    isShowPromoChild: localStorage.getItem('isShowPromoChild')? localStorage.getItem('isShowPromoChild') : true,
    isShowLinkToSocialMediaNetChild: localStorage.getItem('isShowLinkToSocialMediaNetChild')? localStorage.getItem('isShowLinkToSocialMediaNetChild') : true,
    isShowCityChild: localStorage.getItem('isShowCityChild')? localStorage.getItem('isShowCityChild') : true,
    isShowIdChild: localStorage.getItem('isShowIdChild')? localStorage.getItem('isShowIdChild') : true,
    isShowPointerChild: localStorage.getItem('isShowPointerChild')? localStorage.getItem('isShowPointerChild') : true,
    isShowAboutMeChild: localStorage.getItem('isShowAboutMeChild')? localStorage.getItem('isShowAboutMeChild') : true,
    isShowAboutChild: localStorage.getItem('isShowAboutChild')? localStorage.getItem('isShowAboutChild') : true,
    isShowTimeZoneChild: localStorage.getItem('isShowTimeZoneChild')? localStorage.getItem('isShowTimeZoneChild') : true,
    isShowParentNameChild: localStorage.getItem('isShowParentNameChild')? localStorage.getItem('isShowParentNameChild') : true,
    isShowParentEmailChild: localStorage.getItem('isShowParentEmailChild')? localStorage.getItem('isShowParentEmailChild') : true,
    isShowParentPhoneChild: localStorage.getItem('isShowParentPhoneChild')? localStorage.getItem('isShowParentPhoneChild') : true,
    isShowClassesChild: localStorage.getItem('isShowClassesChild')? localStorage.getItem('isShowClassesChild') : true,
    isShowCoursesChild: localStorage.getItem('isShowCoursesChild')? localStorage.getItem('isShowCoursesChild') : true,
    isShowAgreementsChild: localStorage.getItem('isShowAgreementsChild')? localStorage.getItem('isShowAgreementsChild') : true,
    isShowCustomFieldsChild: localStorage.getItem('isShowCustomFieldsChild')? localStorage.getItem('isShowCustomFieldsChild') : true,
    customFieldsChild: [],
    status: 0, // 0 - empy state, 1 - loading state, 2 - state up to date

    isRenameCoursesToTrajectories: localStorage.getItem('isRenameCoursesToTrajectories')? localStorage.getItem('isRenameCoursesToTrajectories') : false,
    elJurSelectPeriodForDisplay: localStorage.getItem('elJurSelectPeriodForDisplay')? localStorage.getItem('elJurSelectPeriodForDisplay') : null,
    disablePopupStatusPMinus: localStorage.getItem('disablePopupStatusPMinus')? localStorage.getItem('disablePopupStatusPMinus') : '0',
    disablePopupStatusPPlus: localStorage.getItem('disablePopupStatusPPlus')? localStorage.getItem('disablePopupStatusPPlus') : '0',
    disablePopupStatusP: localStorage.getItem('disablePopupStatusP')? localStorage.getItem('disablePopupStatusP') : '0',
    disablePopupStatusN: localStorage.getItem('disablePopupStatusN')? localStorage.getItem('disablePopupStatusN') : '0',
    average100bArithmeticMean: localStorage.getItem('average100bArithmeticMean')? localStorage.getItem('average100bArithmeticMean') : '0',
    mainPageAllCoursesInOneBlock: localStorage.getItem('mainPageAllCoursesInOneBlock')? localStorage.getItem('mainPageAllCoursesInOneBlock') : '0'
};
const initialState3 = {
    titleLoginPage: 'Учитесь по-другому',
    subTitleLoginPage: 'Авторизуйтесь, чтобы начать заниматься',
    imageLoginPage: null,
    status: 0
}
export const initialState = {
    ...initialState1,
    ...initialState2,
    ...initialState3
}
console.log(initialState)
const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CHANGE_SETTINGS':
			return {
                ...state,
                ...action.payload
            };
        case SET_ITEM_SETTINGS: {
            if (state[action.key] != action.value)
                localStorage.setItem(action.key, action.value);
            return {
                ...state,
                [action.key]: action.value
            };
        }
        case SET_STATUS_SETTINGS: {
            return {
                ...state,
                status: action.status
            };
        }
		default:
			return state;
	}
};

export default settingsReducer;

export const changeSettings = (data) => {
    return {
        type: 'CHANGE_SETTINGS', 
        payload: data
    }
};

export const actionSetStatus = (status) => ({
    type: SET_STATUS_SETTINGS,
    status,
});

export const actionSetItemSetting = (key, value) => ({
    type: SET_ITEM_SETTINGS,
    key,
    value
});