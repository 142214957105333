import classNames from 'classnames'
import { jsonConvert } from '../../../../helpers/jsonConvert'
import { AnswerButton } from '../AnswerButton/AnswerButton'
import { useTaskHomeWork } from '../hooks/useTaskHomeWork'
import s from './Multi.module.css'
import { formateTime } from '@/helpers/formateTime'
import { useState } from 'react'
import { Tooltip } from 'antd'


export const Multi = ({ type, ...props }) => {
  console.log(type)
  switch (type) {
    case 'decided': return <Decided {...props} />
    case 'correction': return <Correction {...props} />
    default: return <Task type={type} hist={props?.hist}  {...props} />
  }
}

const Correction = () => {
  return <input className={classNames(s.input, s.inputCorrection)} placeholder='Напишите ответ' disabled={true} />
}

const Decided = ({ userRole, ...props }) => {

  const answer = props?.params?.result?.answer
  const correctAnswer = jsonConvert(props.params?.result?.correct_answer, 'json')
  const decidedRight = props?.params?.result?.decided_right
  return <>
    <div className={s.answerWrap}>
    <span className={classNames(s.capsuleLeft, { [s.red]: !decidedRight })}>{userRole === 'children' ? 'Ваш ответ' : 'Ответ ребёнка'}</span>
    <div><span className={s.capsuleRight}>{answer?.length > 0 ? answer : <span className='error'>Нет ответа</span>}</span></div>
    {correctAnswer && <><span className={s.capsuleLeft}>Правильный ответ</span>
      <div><span className={s.capsuleRight}>{(typeof correctAnswer === 'object') ? correctAnswer[0]?.text : correctAnswer}</span></div></>}
  </div>
    {props?.params?.result?.time_spent &&
      <div className={s.estimation}>
        <span className={s.booble}>Потрачено время</span>
        <span className={s.booble}> {formateTime(props?.params?.result?.time_spent)}</span>
      </div>
    }
  </>
}

const Task = ({ hist, params, type, ...props }) => {
  const { onAnswer, answer, status, hint, video, videoType, correctAnswer } = useTaskHomeWork(params,  props)
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleInputChange = (e) => {
    let value = e.target.value;
    let isInvalid = false;

    // Заменяем все запятые на точки
    // value = value.replace(/,/g, '.');

    if (type === 'exact-answer' && props.is_number_answer) {
      const originalValue = value;

      // Разрешаем только цифры, точки и запятые
      // value = value.replace(/[^\d.,]/g, '');

      // Запрещаем точку или запятую в начале строки
      if (value.startsWith('.') || value.startsWith(',')) {
        value = '';
        isInvalid = true;
      }

      // Убедимся, что точка появляется только один раз
      const dotCount = (value.match(/\./g) || []).length;
      if (dotCount > 1) {
        value = value.substring(0, value.lastIndexOf('.'));
      }

      if (originalValue !== value) {
        isInvalid = true;
      }
    }

    setTooltipVisible(isInvalid);
    answer.set(value);
  };

  return <form onSubmit={onAnswer.handler}>
    <Tooltip
      title="В это поле можно ввести только число"
      open={tooltipVisible}
      placement="top"
    ></Tooltip>
    <input className={s.input} value={answer.get} onChange={handleInputChange} placeholder='Напишите ответ' disabled={status > 4} />
    {correctAnswer && hist !== '?redo=ok' &&  params.type !== 'control' && <div className={s.answerWrapTask}>
      <span className={s.capsuleLeft}>Правильный ответ</span>
      <div><span className={s.capsuleRight}>{(typeof correctAnswer === 'object') ? correctAnswer[0]?.text : correctAnswer}</span></div>
    </div>}
    <AnswerButton hintForChild={params.hintForChild} hintAfterAllAttempts={props.hint_after_all_attempts} {...{ status, onAnswer, hint, video, videoType }} />
  </form>
}
